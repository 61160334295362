import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { useEnterStore } from 'hooks/stores/useEnterStore';
import { observer } from 'mobx-react-lite';
import {
  bundlesForEntryMethod,
  calculateLottoOrderCost,
} from 'utils/LottoOrderCostCalculator';
import { useDrawStore } from 'hooks/stores/useDrawStore';
import { PaymentSchedule } from 'utils/constants';
import { EntryMethod } from './enter/EntryMethod';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useInitData } from './enter/hooks/useInitData';
import { goUpgradeOffer } from './enter/upsell/goUpgrade';
import Router, { useRouter } from 'next/router';
import { getCookie } from 'utils/cookie';
import {
  EecProduct,
  trackEecAddToCart,
  trackEecCheckoutStep1,
  trackEecCheckoutStep2,
  trackEvent,
} from 'utils/analytics/analytics';
import { doABTestWithDistribution } from 'utils/analytics/abtest';

interface EnterFlowGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const EnterFlowGlobalContext = observer(
  ({ children }: React.PropsWithChildren<EnterFlowGlobalContextProps>) => {
    const {
      initialised,
      confirmed,
      entryMethod,
      num_entries,
      lines,
      days,
      boosted,
      promo_code,
      discountMultiplier,
      discountMicroAmount,
      discountBannerText,
      paymentSchedule,
      upgradePageSource,
      promoCodeApplicationResult,
      orderId,
      adyenPaymentSession,
      orderError,
      regularOrderCost,
      payNowCost,
      setInitialised,
      setInitialised_promocode,
      setCheckedUserReferral,
      setConfirmed,
      setEntryMethod,
      setNumEntries,
      setLines,
      setLine,
      removeLine,
      setLineNumber,
      setDays,
      toggleDay,
      setBoosted,
      setPromoCode,
      toggleLineNumber,
      toggleLineBonusNumber,
      generateLineRandomNumbers,
      setPromoCodeApplicationResult,
      getPromoCodeMessage,
      setOrder,
      setOrderError,
      setOrderCost,
      validateOrder,
    } = useEnterStore();
    const router = useRouter();
    const { bundles, usingBonusBall, ballRanges } = useDrawStore();

    const { eligibleForIntroOffer, currentUser } = useUserStore();

    const { loadingReferralBonusScheme, isReferralFlow } = useInitData(
      currentUser,
      entryMethod
    );

    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
      const asyncLoadEnter = async () => {
        if (isReferralFlow) {
          trackEvent('isReferralFlow');
          setNumEntries(1);
          setDays('TUESDAY');
          setBoosted(false);
        } else {
          if ([0, 1].includes(num_entries)) {
            setNumEntries(1);
          }

          const ext_promo_code =
            localStorage.getItem('promoCode') || getCookie('promo_code');

          if (ext_promo_code) {
            await setPromoCode(ext_promo_code);
          }
          setLoaded(true);
        }

        setInitialised_promocode(!loadingReferralBonusScheme);
      };

      if (!loadingReferralBonusScheme && !loaded && ballRanges) {
        asyncLoadEnter();
      }
    }, [
      loadingReferralBonusScheme,
      isReferralFlow,
      num_entries,
      ballRanges,
      initialised,
      setInitialised,
    ]);

    useEffect(() => {
      const asyncLoadEnter = async () => {
        if (!loadingReferralBonusScheme && currentUser) {
          if (isReferralFlow) {
            const referralId = getCookie('referral_id');
            await setPromoCode(referralId);
            setLoaded(true);
          }
          setCheckedUserReferral(true);
        }
      };
      asyncLoadEnter();
    }, [loadingReferralBonusScheme, currentUser, isReferralFlow]);

    const chooseNumbers = useCallback(async () => {
      setConfirmed();
      await trackEecAddToCart(
        EecProduct.LOTTERY,
        entryMethod,
        `${lines.length} entry`,
        regularOrderCost
      );
      await trackEecCheckoutStep1(
        EecProduct.LOTTERY,
        entryMethod,
        `${lines.length} entry`,
        regularOrderCost
      );
      await trackEecCheckoutStep2();

      if (!currentUser) {
        //authenticate
        Router.push(`/register?redirect=${encodeURIComponent('checkout')}`);
      } else {
        //go to payment page
        Router.push(`/checkout`);
      }
    }, [currentUser]);

    const navigateToEnterTest = (enterLink: string) => {
      if (isReferralFlow) {
        return router.push(enterLink);
      }
      const testGroup = doABTestWithDistribution('MultiStepEnterFlow2', [
        { choice: 'CONTROL', weight: 1 },
        { choice: 'TEST', weight: 1 },
      ]);
      if (testGroup === 'TEST') {
        return router.push('/enternow/1');
      }

      return router.push(enterLink);
    };

    const actions = useMemo(
      () => ({
        setEntryMethod,
        setNumEntries,
        setLines,
        removeLine,
        setLineNumber,
        setDays,
        toggleDay,
        setBoosted,
        setPromoCode,
        toggleLineNumber,
        toggleLineBonusNumber,
        generateLineRandomNumbers,
        goUpgradeOffer,
        chooseNumbers,
        navigateToEnterTest,
      }),
      [currentUser]
    );

    const discountFirstEntry =
      entryMethod === EntryMethod.PremiumSubscription &&
      !isReferralFlow &&
      eligibleForIntroOffer;

    const firstEntryDiscount = discountFirstEntry ? 0.5 : 0;

    useEffect(() => {
      (async () => {
        const calcCost = async () => {
          const orderCosts = await calculateLottoOrderCost(
            num_entries,
            bundles,
            entryMethod,
            firstEntryDiscount,
            days,
            false,
            discountMultiplier,
            discountMicroAmount,
            boosted,
            PaymentSchedule.WEEKLY,
            1
          );

          setOrderCost(orderCosts.regularOrderCost, orderCosts.payNowCost);
        };

        if (bundles.length > 0) {
          await calcCost();

          if (!initialised) {
            setInitialised(true);
          }
        }
      })();
    }, [
      loaded,
      num_entries,
      bundles,
      entryMethod,
      firstEntryDiscount,
      days,
      false,
      discountMultiplier,
      discountMicroAmount,
      boosted,
      isReferralFlow,
    ]);

    const isValid = ballRanges && validateOrder();

    const entryMethodBundles = bundlesForEntryMethod(
      bundles,
      entryMethod,
      paymentSchedule
    );

    const promoCodeMessages = [];

    if (promoCodeApplicationResult) {
      const promoCodeMessage = getPromoCodeMessage();

      if (promoCodeMessage) {
        promoCodeMessages.push(promoCodeMessage);
      }
    }

    if (promoCodeMessages.length > 0 && firstEntryDiscount) {
      promoCodeMessages.push('Your 1st entry has been discounted by 50%');
    }

    return (
      <GlobalActionsProvider
        contextName="EnterFlowGlobalContext"
        actions={actions}
      >
        <DataProvider
          name="enterFlow"
          data={{
            entryMethod,
            num_entries,
            lines,
            days,
            boosted,
            entryMethodBundles,
            promo_code,
            promoCodeApplicationResult,
            promoCodeMessages,
            discountMultiplier,
            discountMicroAmount,
            discountBannerText: isReferralFlow
              ? '4 weeks free'
              : discountBannerText
              ? discountBannerText
              : discountFirstEntry
              ? '50% off 1st entry'
              : null,
            regularOrderCost,
            payNowCost: isReferralFlow ? 0 : payNowCost,
            orderError,
            orderId,
            isValid,
            isReferralFlow,
          }}
        >
          {children}
        </DataProvider>
      </GlobalActionsProvider>
    );
  }
);
