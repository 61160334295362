import {
  EecProduct,
  fetchUtmParamsJoinedWithFbDataFromLocalStorage,
  trackEecCheckoutOption,
  trackEecCheckoutStep3,
  trackEecCheckoutStep4,
  trackInitiateCheckoutEvent,
  trackPurchase,
} from 'utils/analytics/analytics';
import AdyenDropInCheckout from './AdyenDropInCheckout';
import { SOURCE_QUERY_PARAM } from 'pages/validate-3ds-payment';
import { useCallback, useEffect, useRef } from 'react';
import { useEnterStore } from 'hooks/stores/useEnterStore';
import { getCookie, removeCookie } from 'utils/cookie';
import {
  createOrder,
  createSubscriptionDetailsObject,
  getOrderDetails,
} from 'utils/api/order';
import { OrderType } from './PayPalAdyenCheckout';
import { TicketOrder } from 'types/OrderDetails';
import Router, { useRouter } from 'next/router';
import { useDrawStore } from 'hooks/stores/useDrawStore';
import { useUserStore } from 'hooks/stores/useUserStore';
import { EntryMethod } from 'components/enter/EntryMethod';
import { PaymentSchedule, runningInProduction } from 'utils/constants';
import md5 from 'md5';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import { observer } from 'mobx-react-lite';
import { Message } from 'semantic-ui-react';
import { navigateToConfirmation } from 'utils/navigation';

interface IPlasmicEnterCheckoutProps {}

export const PlasmicEnterCheckout = observer(
  ({}: IPlasmicEnterCheckoutProps) => {
    const router = useRouter();

    const {
      initialised,
      initialised_promocode,
      checked_user_referral,
      confirmed,
      entryMethod,
      lines,
      days,
      boosted,
      promo_code,
      discountMultiplier,
      discountMicroAmount,
      discountBannerText,
      paymentSchedule,
      upgradePageSource,
      promoCodeApplicationResult,
      orderId,
      adyenPaymentSession,
      adyenPaymentSessionTime,
      regularOrderCost,
      payNowCost,
      setPromoCodeApplicationResult,
      setOrder,
      setOrderError,
      validateOrder,
    } = useEnterStore();
    const { bundles, usingBonusBall, ballRanges } = useDrawStore();

    const {
      initialized: userStoreInitialised,
      eligibleForIntroOffer,
      currentUser,
    } = useUserStore();
    // Holds a reference to the timer that periodically checks for order completion.
    // Needed so we can clear it when the component unmounts.
    const orderIdRef = useRef<string | null>(orderId);
    const loadingRef = useRef<boolean>(false);
    const orderCompletionTimerRef = useRef<any>(null);
    const tenMinutes = 10 * 60 * 1000;

    const isValid = validateOrder();

    useEffect(() => {
      return () => {
        if (orderCompletionTimerRef.current) {
          clearTimeout(orderCompletionTimerRef.current);
        }
      };
    }, []);

    useEffect(() => {
      orderIdRef.current = orderId;
    }, [orderId]);

    const haveValidAdyenSession =
      adyenPaymentSession &&
      adyenPaymentSessionTime &&
      Date.now() - adyenPaymentSessionTime < tenMinutes;

    useEffect(() => {
      if (haveValidAdyenSession && !orderCompletionTimerRef.current) {
        // Start periodically checking whether order is complete.
        clearTimeout(orderCompletionTimerRef.current);
        checkOrderStatusAndAdvanceToConfirmation();
      }
    }, [
      haveValidAdyenSession,
      orderIdRef.current,
      orderCompletionTimerRef.current,
    ]);

    useEffect(() => {
      if (runningInProduction) {
        if (!isValid || !confirmed) {
          router.replace('/enter-now');
        } else {
          if (userStoreInitialised && !currentUser) {
            router.replace('/enter-now');
          }
        }
      }
    }, [isValid, confirmed, userStoreInitialised, currentUser]);

    const checkOrderStatusAndAdvanceToConfirmation = async () => {
      console.log('Waiting for payment', orderIdRef.current);
      if (!orderIdRef.current) {
        return;
      }

      const jwt = getCookie('jwt') || getCookie('guest_jwt');
      const orderDetails = await getOrderDetails(
        jwt,
        orderIdRef.current,
        OrderType.TICKET_ORDER
      );

      if (orderDetails?.order?.state === 'COMPLETE') {
        return await onSuccessfulPayment(orderDetails);
      } else {
        orderCompletionTimerRef.current = setTimeout(
          checkOrderStatusAndAdvanceToConfirmation,
          2000
        );
      }
    };

    const onSuccessfulPayment = async (orderDetails: TicketOrder) => {
      removeCookie('referral_id');
      removeCookie('promoCode');

      const promoCodeUsed =
        orderDetails.promoCodeEntries?.length > 0
          ? orderDetails.promoCodeEntries[0].code_used
          : orderDetails.promoCodeDiscount?.length > 0
          ? orderDetails.promoCodeDiscount[0].code_used
          : null;

      await trackPurchase(
        orderDetails.order.payment_cost,
        orderDetails.id,
        orderDetails.ticket,
        orderDetails.ticket2,
        orderDetails.raffleTicket,
        entryMethod,
        currentUser?.email_address ? md5(currentUser.email_address) : '',
        promoCodeUsed,
        orderDetails.order.firstTimePlayer,
        currentUser?.id,
        {
          drawDays: orderDetails.drawDays.join(','),
          numEntries: orderDetails.ticket.entries.length,
          boosted: orderDetails.ticket.boosted || orderDetails.ticket2?.boosted,
          billings:
            parseInt(orderDetails.ticket.billings) +
            parseInt(orderDetails.ticket2?.billings || '0'),
          cost:
            parseInt(orderDetails.ticket.cost) +
            parseInt(orderDetails.ticket2?.cost || '0'),
        }
      );

      await navigateToConfirmation(router, orderDetails.id, true);
    };

    const handleContinueToPayment = async () => {
      if (haveValidAdyenSession) {
        return { orderId, adyenPaymentSession };
      }

      loadingRef.current = true;
      console.log(
        'handleContinueToPayment payment',
        payNowCost,
        promoCodeApplicationResult
      );

      const jwt = getCookie('jwt') || getCookie('guest_jwt');

      const affiliateFutureAffc = getCookie('Affc', null);

      const subscriptionDetails =
        entryMethod === EntryMethod.OneOff
          ? null
          : createSubscriptionDetailsObject(
              entryMethod as any,
              paymentSchedule === PaymentSchedule.MONTHLY
            );

      const drawDays = [];

      if (days == 'BOTH') {
        drawDays.push('TUESDAY');
        drawDays.push('FRIDAY');
      } else {
        drawDays.push(days);
      }

      const sourceFor3DS = upgradePageSource
        ? SOURCE_QUERY_PARAM.UPGRADE
        : 'enter-now';

      const utmParams = fetchUtmParamsJoinedWithFbDataFromLocalStorage();

      const result = await createOrder(
        jwt,
        lines.map((entry) => [
          ...entry.main.map((n) => n!.toString()),
          String(entry.bonus),
        ]),
        payNowCost,
        subscriptionDetails,
        drawDays,
        boosted,
        promoCodeApplicationResult,
        0,
        utmParams,
        null,
        [],
        affiliateFutureAffc,
        sourceFor3DS
      );
      if (result.successful === false) {
        setPromoCodeApplicationResult(result);
        setOrderError(result.reason);
        return;
      }

      trackInitiateCheckoutEvent();

      await trackEecCheckoutStep4(
        EecProduct.LOTTERY,
        entryMethod,
        `${lines.length} entry`,
        regularOrderCost,
        false
      );

      setOrder(result.order.id, result.order.adyenPaymentSession);

      loadingRef.current = false;

      return { orderId, adyenPaymentSession };
    };

    useEffect(() => {
      if (
        initialised &&
        initialised_promocode &&
        checked_user_referral &&
        !haveValidAdyenSession &&
        ballRanges &&
        !loadingRef.current &&
        isValid &&
        currentUser
      ) {
        handleContinueToPayment();
      }
    }, [
      haveValidAdyenSession,
      ballRanges,
      initialised,
      initialised_promocode,
      checked_user_referral,
      !loadingRef.current,
    ]);

    if (!isValid) {
      return <Message error>Order not valid</Message>;
    }
    if (!confirmed) {
      return <Message error>Order not confirmed</Message>;
    }

    if (!haveValidAdyenSession) {
      return <PlainLoadingSpinner style={{ margin: '2em auto' }} />;
    }

    return (
      <AdyenDropInCheckout
        queryParamsObject={{
          source: upgradePageSource
            ? SOURCE_QUERY_PARAM.UPGRADE
            : SOURCE_QUERY_PARAM.STANDARD_TICKET_ORDER,
        }}
        paymentSession={adyenPaymentSession}
        onLoadingStateChange={(loading: boolean) => {
          //   setLoading(loading);
          //   scrollToSection('#__next');
        }}
        completionHandler={async () => {
          await trackEecCheckoutOption('Card');
        }}
        displayError={async (error: string) => {
          setOrderError(error);
          //   if (!error) return;
        }}
        style={{
          margin: '2em auto',
          fontSize: '12px',
          width: '100%',
          maxWidth: 500,
        }}
        initialRedirectResult={undefined}
      />
    );
  }
);
